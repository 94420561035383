// External modules
import React from "react";
import { useTranslation } from "react-i18next";

// Resources
import CVIcon from "../../resources/icons/doc-icon.png";
import LinkedInLogo from "../../resources/icons/linkedin-logo.png";
import GitHubLogo from "../../resources/icons/github-logo.png";

// Style sheet
import "./Home.scss";

const Home = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="home">
      <header>
        <button
          className="header-btn "
          onClick={() => i18n.changeLanguage("es")}
        >
          ES
        </button>
        <button
          className="header-btn"
          onClick={() => i18n.changeLanguage("en")}
        >
          EN
        </button>
      </header>
      <div id="name-container">
        <h1>ALBERT MERCADÉ</h1>
        <h3>{t("jobTitle")}</h3>
      </div>

      <div id="links">
        <a
          className="link-btn"
          id="linkedin"
          target="_blank"
          rel="noreferrer"
          href={t("linkedin.url")}
        >
          <img src={LinkedInLogo} className="btn-icon" alt="GitHub logo" />
          <p className="btn-text">LinkedIn</p>
        </a>
        <a
          className="link-btn"
          id="github"
          target="_blank"
          rel="noreferrer"
          href="https://github.com/albertmercade"
        >
          <img src={GitHubLogo} className="btn-icon" alt="GitHub logo" />
          <p className="btn-text">GitHub</p>
        </a>
        <div className="link-dropdown">
          <div className="cv-button">
            <img src={CVIcon} className="btn-icon" alt="CV icon" />
            <p className="btn-text">CV (PDF)</p>
          </div>
          <div id="link-cv-wrapper">
            <a target="_blank" rel="noreferrer" href={t("cv.en.url")}>
              {t("lang.en")}
            </a>
            <a target="_blank" rel="noreferrer" href={t("cv.es.url")}>
              {t("lang.es")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
