import React from "react";
import { Oval } from "react-loader-spinner";

const Loading = () => {
  return (
    <Oval
      color="#c0c0c0"
      secondaryColor="red"
      wrapperStyle={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  );
};

export default Loading;
