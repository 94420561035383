import React, { Suspense } from "react";

import Loading from "./components/loading/Loading";
import Home from "./pages/home/Home";

import "./App.scss";

const App = () => {
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Home />
      </Suspense>
    </div>
  );
};

export default App;
